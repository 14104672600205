import React, {useContext, useEffect, useState} from 'react';
import {createStyles, fade, makeStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, useHistory} from "react-router-dom";
import {AppContext} from '../_context/AppState';
import {Types} from '../_context/AppTypes';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DirectionsIcon from '@material-ui/icons/Directions';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import TemporaryDrawer from '../App/Drawer'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import UserService from "../_services/user.service";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ActionMenu from './ActionMenu';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        paperToolbar: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },

        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        fabButton: {
            zIndex: 1,
            position: 'fixed',
            bottom: '10px',
            left: '330px',
        },
        listPlan: {
            width: '100%',
        },
        typography: {
            padding: theme.spacing(2),
        },
    }),
);

export default function ListPlan() {
    const classes = useStyles();
    let history = useHistory();

    const {state, dispatch} = useContext(AppContext);

    const [filterPlan, setFilterPlan] = useState("");
    const [itemAction, setItemAction] = useState();

    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
    const [currentPlanIDMenu, setCurrentPlanIDMenu] = useState<Number>(0);

    const listItem = [];

    // Filter plan by name
    let plansFilter = [];
    if (filterPlan.length > 0) {
        plansFilter = state.plans.filter((plan: any) => plan.name.toLowerCase().indexOf(filterPlan.toLowerCase()) >= 0);
    } else {
        plansFilter = state.plans;
    }

    const handleClickMenu = (id: Number) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
        setCurrentPlanIDMenu(id) // set to current value
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const handleMouseHoverList = (planID: Number) => {
        setItemAction(planID);
    }
    const handleClickPlan = (planID: Number) => {
        history.push(`/plan/${planID}`);
    }

    const handleChangeFilterPlan = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterPlan(event.target.value.trim());
    };

    const handleSubmitFilterPlan = (event: any) => {
        event.preventDefault();
    };


    const {setDrawerOpen} = useContext(AppContext)

    const handleDrawer = () => {
        setDrawerOpen(true);
    }


    for (const plan of plansFilter) {
        // TODO: when createnewplan, backto listplan -> duplicate with plan ID=0
        if (plan.id == 0)
            continue;

        listItem.push(<div key={plan.id}>
            <ListItem button key={plan.id} onClick={() => handleClickPlan(plan.id)}
                      onMouseOver={() => handleMouseHoverList(plan.id)}>
                <ListItemText primary={plan.name}
                              secondary={plan.description.substr(0, 45)}/>
                {itemAction == plan.id ?
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={handleClickMenu(plan.id)}>
                            <MoreHorizIcon/>
                        </IconButton>
                    </ListItemSecondaryAction> : null}
            </ListItem>
            <Divider></Divider>
        </div>)
    }

    useEffect(() => {
        UserService.getPlans().then(
            (data) => {
                dispatch({type: Types.ListPlan, payload: data})
            },
            (error) => {
                console.log("UserService.getPlans():", error)
            }
        );


        return () => {
        };

    }, []);

    return (
        <div className={classes.root}>
            <Paper component="form" className={classes.paperToolbar}>
                <IconButton onClick={handleDrawer} className={classes.iconButton} aria-label="menu">
                    <MenuIcon/>
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{'aria-label': 'search'}}
                    onChange={handleChangeFilterPlan()}
                />
                <IconButton type="submit" onClick={handleSubmitFilterPlan} className={classes.iconButton}
                            aria-label="search">
                    <SearchIcon/>
                </IconButton>

                <Divider className={classes.divider} orientation="vertical"/>
                <IconButton disabled color="primary" className={classes.iconButton} aria-label="directions">
                    <DirectionsIcon/>
                </IconButton>
            </Paper>

            <TemporaryDrawer/>

            <List component="nav" className={classes.listPlan} aria-label="main mailbox folders">
                {listItem}
            </List>

            <ActionMenu currentPlanIDMenu={currentPlanIDMenu} anchorElMenu={anchorElMenu}
                        handleCloseMenu={handleCloseMenu}/>

            <Link to="/add-plan">
                <Fab size="medium" color="secondary" aria-label="add" className={classes.fabButton}>
                    <AddIcon/>
                </Fab>
            </Link>
        </div>
    );
}