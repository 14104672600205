import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {AppContext} from "../_context/AppState";
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {ReportModal} from '../_constants/const';
import Typography from '@material-ui/core/Typography';
import LogoDvs from '../_assets/images/logodvs.png';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    version: {
        bottom: 0,
        position: 'absolute',
        marginLeft: '20px',
    }
});

type Anchor = 'left';

export default function TemporaryDrawer() {
    const classes = useStyles();

    const {drawerOpen, setDrawerOpen, setPlanReportModal, setAlertReportModal, setSettingModal} = useContext(AppContext)

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open)
    };

    const handleMenuAlertReport = () => {
        setAlertReportModal(ReportModal.OPEN_NO_PLAN);
    }

    const handleMenuPlanReport = () => {
        setPlanReportModal(ReportModal.OPEN_NO_PLAN);
    }

    const handleMenuSetting = () => {
        setSettingModal(ReportModal.OPEN_NO_PLAN);
    }

    return (
        <React.Fragment key="left">
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button key={0}>
                            <img src={LogoDvs}/>
                        </ListItem>
                        <Divider/>
                        <ListItem button key={1} onClick={handleMenuAlertReport}>
                            <ListItemIcon><NotificationsIcon/></ListItemIcon>
                            <ListItemText primary={"Alert Report"}/>
                        </ListItem>
                        <ListItem button key={2} onClick={handleMenuPlanReport}>
                            <ListItemIcon><AssessmentIcon/></ListItemIcon>
                            <ListItemText primary={"Plan Report"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem button key={3} onClick={handleMenuSetting}>
                            <ListItemIcon><SettingsIcon/></ListItemIcon>
                            <ListItemText primary={"Setting"}/>
                        </ListItem>
                    </List>
                </div>
                <div className={classes.version}>
                    <Typography variant="overline" display="block" gutterBottom>Version v1.0.9</Typography>
                </div>
            </Drawer>
        </React.Fragment>
    );
}
