import {ReactIsInDevelomentMode} from '../_utilities/utilities'

const HOSTPlanDev = "http://localhost:8084";
const HOSTMainDev = "http://localhost:8080";

const HOSTMainProd = "https://itracking.vn/gapi";
const HOSTPlanProd = "https://planapi.itracking.vn";

export enum API {
    Login = "/v2.0/login",
    GETStatusVehicles = "/v2.0/status",
    POSTPlanReport = "/v2.1/report/plan",
    POSTAlertReport = "/v2.1/report/alert",
    GETAlerts = "/v2.1/alerts",
    POSTHasReadAlerts = "/v2.1/alerts/read",
    GETCountAlerts = "/v2.1/count-alerts",
    GETPlans = "/v2.1/plans",
    POSTPlans = "/v2.1/plans",
    PUTPlans = "/v2.1/plans",
    SearchVehicles = "/v2.1/vehicles/:search",
    GetVehicles = "/v2.1/vehicles",
    DELETEPlans = "/v2.1/plans/:planID",
    GETPlanDetail = "/v2.1/plans/:planID",
}

export const BuildApi = function (api: API, planID?: Number): string {
    let hostMain, hostPlan: string;
    if (ReactIsInDevelomentMode()) {
        hostMain = HOSTMainDev;
    } else {
        hostMain = HOSTMainProd;
    }
    if (ReactIsInDevelomentMode()) {
        hostPlan = HOSTPlanDev;
    } else {
        hostPlan = HOSTPlanProd;
    }

    switch (api) {
        case API.Login:
            return hostMain + api;

        case API.GETStatusVehicles:
            return hostMain + api;

        case API.GetVehicles:
            return hostMain + api;

        default:
            if (typeof planID !== 'undefined')
                return hostPlan + api.replace(':planID', String(planID));

            break;
    }

    return hostPlan + api;
};

