// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserService from "../_services/user.service";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AppContext} from "../_context/AppState";
import {PlanVehicleType, Types, VehicleType} from '../_context/AppTypes';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        chip: {
            margin: theme.spacing(0.5),
          },
    }),
);


export default function Vehicles() {
    const classes = useStyles();

    const {state, dispatch} = useContext(AppContext);

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<VehicleType[]>([]);
    const [vehiclePlan, setVehiclePlan] = React.useState<PlanVehicleType[]>(function () {
        if (state.currentPlan.planVehicles)
            return state.currentPlan.planVehicles;
        return [];
    });

    const [emailsPlan, setEmailsPlan] = React.useState<string[]>(function () {
        if (state.currentPlan.planEmails)
            return state.currentPlan.planEmails;
        return [];
    });


    const loading = open && options.length === 0;

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRemoveVehicle = (id: string) => {
        const tmpVehicle = [...vehiclePlan.filter(veh => veh.deviceID !== id)];
        setVehiclePlan(tmpVehicle);
        // TODO: reducer currentPlan NOT array of plan
        dispatch({
            type: Types.UpdatePlan, payload: {
                vehicles: tmpVehicle,
                id: state.plans[0].id,
            }
        });
    };

    const handleEmailDelete = (emailToDelete: string) => () => {
        setEmailsPlan((emailsPlan) => emailsPlan.filter((email) => email !== emailToDelete));
      };

    const handleOnChangeEmails = (newValue: any) => {
        if (newValue) {
            let set = new Set();
            const tmpEmails = [...emailsPlan, ...newValue].filter(item => {
                if (!set.has(item)) {
                    set.add(item);
                    return true;
                }
                return false;
            }, set)


            setEmailsPlan(tmpEmails);

            // Update global store
            // TODO: reducer currentPlan NOT array of plan
            dispatch({
                type: Types.UpdatePlan, payload: {
                    planEmails: tmpEmails,
                }
            });
        }
    };

    const handleOnChangeAutoComplete = (newValue: any) => {
        if (newValue) {
            let newPlanVehicle: PlanVehicleType = {deviceID: newValue.id, deviceName: newValue.name};
            let set = new Set();
            const tmpVehicle = [...vehiclePlan, newPlanVehicle].filter(item => {
                if (!set.has(item.deviceID)) {
                    set.add(item.deviceID);
                    return true;
                }
                return false;
            }, set)

            setVehiclePlan(tmpVehicle);

            // Update global store
            // TODO: reducer currentPlan NOT array of plan
            dispatch({
                type: Types.UpdatePlan, payload: {
                    planVehicles: tmpVehicle,
                }
            });
        }
    };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        UserService.getVehicles().then(
            (data) => {
                if (active) {
                    setOptions(Object.keys(data.devices).map((key) => data.devices[key]) as VehicleType[]);
                }
            },
            (error) => {
                console.log("UserService.getPlans():", error)
            }
        );


        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <React.Fragment>
               <Autocomplete
                disableClearable
                options={[]}
                freeSolo
                multiple
                value={emailsPlan}
                onChange={(event, newValue) => handleOnChangeEmails(newValue)}
                renderTags={(value, props) =>
                    value.map((option, index) => (
                    <Chip label={option} {...props({ index })} />
                    ))
                }
                renderInput={(params) => <TextField label="Emails Alert" {...params} />}
                />
                <br/>

            <Autocomplete
                id="asynchronous-demo"
                style={{width: 350}}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, newValue) => handleOnChangeAutoComplete(newValue)}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Vehicle"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />

            <div>
                {vehiclePlan.map((item) => (
                    <Accordion key={item.deviceID} expanded={expanded === item.deviceID}
                               onChange={handleChangeAccordion(item.deviceID)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={item.deviceID + "bh-content"}
                            id={item.deviceID + "bh-header"}
                        >
                            <Typography className={classes.heading}>{item.deviceName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        </AccordionDetails>
                        <Divider/>
                        <AccordionActions>
                            <Button size="small" color="primary" onClick={e => handleRemoveVehicle(item.deviceID)}>
                                Remove
                            </Button>
                        </AccordionActions>
                    </Accordion>
                ))}
            </div>


        </React.Fragment>

    );
}
