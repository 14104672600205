import axios from "axios";
import authHeader from "./auth-header";
import {API, BuildApi} from "../_constants/apis"

const postPlans = (data) => {
    return axios
        .put(BuildApi(API.POSTPlans), data, {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }
            return response.data;
        });
};

const putPlans = (data) => {
    return axios
        .put(BuildApi(API.PUTPlans), data, {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data;
        });
};

//getStatusVehicles
const getStatusVehicles = () => {
    const header = authHeader();
    if (!("X-Auth-Token" in header)) {
        return undefined;
    }
    // this use POST
    return axios
        .post(BuildApi(API.GETStatusVehicles), {}, {headers: header})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};
const getPlans = () => {
    return axios
        .get(BuildApi(API.GETPlans), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};

const getPlanDetail = (planId) => {
    return axios
        .get(BuildApi(API.GETPlanDetail, planId), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};


const getVehicles = () => {
    return axios
        .get(BuildApi(API.GetVehicles), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data;
        });
};

const deletePlans = (planID) => {
    return axios
        .delete(BuildApi(API.DELETEPlans, planID), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data;
        });
};

const getPlanReport = (data) => {
    return axios
        .post(BuildApi(API.POSTPlanReport), data, {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};
const getAlertReport = (data) => {
    return axios
        .post(BuildApi(API.POSTAlertReport), data, {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};

const getAlerts = () => {
    return axios
        .get(BuildApi(API.GETAlerts), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};

const getCountAlerts = () => {
    return axios
        .get(BuildApi(API.GETCountAlerts), {headers: authHeader()})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};


//hasReadAlerts
const hasReadAlerts = (data) => {
    const header = authHeader();
    if (!("X-Auth-Token" in header)) {
        return undefined;
    }
    // this use POST
    return axios
        .post(BuildApi(API.POSTHasReadAlerts), data, {headers: header})
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};


export default {
    getStatusVehicles,
    getPlans,
    getPlanDetail,
    putPlans,
    postPlans,
    getVehicles,
    getAlerts,
    hasReadAlerts,
    getPlanReport,
    getAlertReport,
    getCountAlerts,
    deletePlans,
};
