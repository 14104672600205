import React, {useContext, useState} from 'react';
import L from "leaflet";

import {AppContext} from "../_context/AppState";
import {KeyDeviceID} from "../_constants/const";
import UserService from "../_services/user.service";
import {VehicleType} from "../_context/AppTypes";
import {useInterval} from "./useInterval";
import {GetVehicleIconByAngle} from "../_utilities/icon";

function useVehicles() {
    const {markersCluster} = useContext(AppContext)
    const [vehiclesMap, setVehiclesMap] = useState<Map<string, VehicleType>>(new Map<string, VehicleType>());

    const vehicleStatus = (veh: VehicleType) => {
        if (!veh.latitude || !veh.longitude) {
            return
        }
        let vehicleExist = vehiclesMap.get(veh.id);
        if (vehicleExist) {
            if (vehicleExist.marker) {
                // Dont set new location when no change
                let latLng = vehicleExist.marker.getLatLng();
                if (latLng.lat != veh.latitude || latLng.lat != veh.longitude)
                    vehicleExist.marker.setLatLng([veh.latitude, veh.longitude]);
                vehicleExist.marker.setIcon(GetVehicleIconByAngle(veh.angle));
            }
        } else {
            let marker = L.marker([veh.latitude, veh.longitude]);
            marker.bindPopup(`<b>${veh.name}</b>`);
            marker.setIcon(GetVehicleIconByAngle(veh.angle));
            marker.bindTooltip(veh.name, {
                permanent: true,
                direction: "top",
                offset: [0, -10],
                className: "vehicle-tooltip"
            }).openTooltip();

            // @ts-ignore
            marker[KeyDeviceID] = veh.id;

            veh.marker = marker

            setVehiclesMap(new Map(vehiclesMap.set(veh.id, veh)));
        }
    };

    const updateMarkerCluster = () => {
        let markers: L.Marker[] = [];
        const layers = markersCluster.current.getLayers();

        vehiclesMap.forEach(function (veh, keyDeviceID) {
            let isExistMarker = false;
            for (let idx = 0; idx < layers.length; idx++) {
                let layer = layers[idx];
                // @ts-ignore
                let deviceID = layer[KeyDeviceID];
                if (deviceID == keyDeviceID) {
                    isExistMarker = true;
                    break;
                }
            }
            if (isExistMarker == false)
                if (veh.marker instanceof L.Marker) {
                    markers.push(veh.marker);
                }
        });

        if (markersCluster.current && markers.length > 0) {
            markersCluster.current.addLayers(markers);
            //clusterControl.current.refreshClusters();
        }
    };

    useInterval(() => {
        const stsVeh = UserService.getStatusVehicles();
        if (stsVeh) {
            stsVeh.then((data) => {
                    if (data.devices) {
                        // process data status
                        for (let i = 0; i < data.devices.length; i++) {
                            const dev = data.devices[i];
                            let vehStatus = dev.roadmaps[0];
                            vehStatus.id = dev.id; // assign deviceID
                            vehStatus.name = dev.name; // assign deviceID

                            vehicleStatus(vehStatus);
                        }
                        // Update cluster
                        updateMarkerCluster();
                    }
                },
                (error) => {
                    console.log("UserService.getStatusVehicles():", error);
                }
            );
        }
        ;
    }, 5000)
}

export default useVehicles;