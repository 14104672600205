import React, {useContext, useEffect, useRef} from 'react';
import L, {LatLng} from "leaflet";

import {AppContext} from "../_context/AppState";
import {TokenGG, TokenMB} from "../_constants/const";
import {ReactIsInDevelomentMode} from '../_utilities/utilities'

require("../_lib/lrm-google/lrm-google");
require('../_lib/leaflet-routing-machine');
require('leaflet-control-geocoder');

let googleRouter: any, geocoder: any;
if (ReactIsInDevelomentMode()) {
    // @ts-ignore
    geocoder = L.Control.Geocoder.mapbox(TokenMB);

    googleRouter = L.routing.mapbox(TokenMB, {})
} else {
    // @ts-ignore
    geocoder = L.Control.Geocoder.google(TokenGG);

    // @ts-ignore
    googleRouter = new L.Routing.Google();
}


function useRoutingControl(domEject: string, waypoints: LatLng[]) {
    const {mapRef, setRoute} = useContext(AppContext)

    const routingControl = useRef<L.Routing.Control | null>(null)

    const appendChildRouting = function () {
        const domRM = document.getElementById(domEject);

        if (domRM && routingControl.current) {
            const container = routingControl.current.getContainer();
            if (container)
                // @ts-ignore
                domRM.appendChild(container);
        }
    }

    useEffect(() => {
        // parse to waypoint object
        var latLngs: LatLng[] = [];
        if (waypoints && waypoints.length > 0) {
            waypoints.forEach((wp: any) => latLngs.push(wp.latLng));
        }

        if (routingControl.current == null) {
            routingControl.current = new L.Routing.Control({
                show: true,
                showAlternatives: false,
                minimizedClassName: "minimizedRouting",
                router: googleRouter,
                plan: L.Routing.plan(latLngs, {
                    createMarker: function (i, wp) {
                        return L.marker(wp.latLng, {
                            draggable: true,
                            icon: L.icon.glyph({glyph: String.fromCharCode(65 + i)})
                        });
                    },
                    geocoder: geocoder,
                    routeWhileDragging: true
                }),
                waypoints: latLngs
            });
        } else {
            routingControl.current.setWaypoints(latLngs);
            routingControl.current.route()
        }

        if (routingControl.current)
            routingControl.current.on('routeselected', (e: any) => {
                var route = e.route;

                var coordinates = [];
                for (let idx = 0; idx < route.coordinates.length; idx++) {
                    const latLng = route.coordinates[idx];
                    coordinates.push([latLng.lng, latLng.lat]);
                }

                setRoute(route);
            });
        routingControl.current.on('routesfound', (e: any) => {
            var route = e.route;
        });

        if (mapRef.current) {
            routingControl.current.addTo(mapRef.current)

            appendChildRouting();
        }

        return () => {
        };
    }, [waypoints])

    return [routingControl, appendChildRouting] as const;
}

export default useRoutingControl;