import {LatLng} from "leaflet";
import {newPlanType, PlanType, Types, VehicleType} from "./AppTypes";

type PlanPayload = {
    [Types.SavePlan]: { id: number; name: string; waypoints: LatLng[]; };
    [Types.DeletePlan]: { id: number; };
    [Types.ListPlan]: PlanType[];
    [Types.GetPlan]: PlanType;
    [Types.CreatePlan]: PlanType;
    [Types.UpdatePlan]: PlanType;
};

export type PlanActions = ActionMap<PlanPayload>[keyof ActionMap<PlanPayload>];

type CurrentPlanPayload = {
    [Types.GetPlan]: PlanType;
    [Types.CreatePlan]: PlanType;
    [Types.UpdatePlan]: PlanType;
    [Types.EmptyPlan]: PlanType;
};
export type CurrentPlanActions = ActionMap<CurrentPlanPayload>[keyof ActionMap<CurrentPlanPayload>];

type VehiclePayload = {
    [Types.SaveVehicles]: VehicleType[];
    [Types.ListVehicle]: VehicleType[];
};
export type VehicleActions = ActionMap<VehiclePayload>[keyof ActionMap<VehiclePayload>];


const Plan = createMsg<PlanPayload>();

export const planReducer = (state: PlanType[], action: PlanActions | CurrentPlanActions | VehicleActions): PlanType[] => {
    switch (action.type) {
        /*        case Types.Save:
                    const payload = action.payload
                    const index = state.findIndex((plan) => plan.id === payload.id);
                    console.log(payload, index);

                    if (index === -1) {
                        return [
                            ...state,
                            {
                                id: 100,
                                name: payload.name,
                                waypoints: payload.waypoints,
                            }
                        ];
                    }

                    state[index] = payload;
                    return state;*/

        case Types.DeletePlan:
            return [...state.filter(plan => plan.id !== action.payload.id)];

        case Types.ListPlan:
            const mergedArray = [...state, ...action.payload];
            // mergedArray have duplicates, lets remove the duplicates using Set
            let set = new Set();
            let unionArray = mergedArray.filter(item => {
                if (!set.has(item.id)) {
                    set.add(item.id);
                    return true;
                }
                return false;
            }, set);

            return unionArray;

        /* case Types.GetPlan:
             const indexPlan = state.findIndex((plan) => plan.id === action.payload.id);
             state[indexPlan] = action.payload
              return state;

         case Types.CreatePlan:
             return [newPlanType ];

         case Types.UpdatePlan:
             const indexUpPlan = state.findIndex((plan) => plan.id === action.payload.id);
             const upPlan = state[indexUpPlan];
             if(typeof action.payload === 'object' && action.payload.hasOwnProperty('name')) {
                 upPlan.name = action.payload.name;
             }
             if(typeof action.payload === 'object' && action.payload.hasOwnProperty('planVehicles')) {
                 upPlan.planVehicles = action.payload.planVehicles;
             }
             // ASSIGN
             state[indexUpPlan] = upPlan;

             return state;*/

        default:
            return state;
    }
};


export const currentPlanReducer = (state: PlanType, action: PlanActions | CurrentPlanActions | VehicleActions): PlanType => {
    switch (action.type) {
        case Types.GetPlan:
            return action.payload;

        case Types.UpdatePlan:
            if (typeof action.payload === 'object' && action.payload.hasOwnProperty('name')) {
                state.name = action.payload.name;
            }

            if (typeof action.payload === 'object' && action.payload.hasOwnProperty('planVehicles')) {
                state.planVehicles = action.payload.planVehicles;
            }
            if (typeof action.payload === 'object' && action.payload.hasOwnProperty('planEmails')) {
                state.planEmails = action.payload.planEmails;
            }
            if (typeof action.payload === 'object' && action.payload.hasOwnProperty('id')) {
                state.id = action.payload.id;
            }

            if (typeof action.payload === 'object' && action.payload.hasOwnProperty('planGeoExceptions')) {
                state.planGeoExceptions = action.payload.planGeoExceptions;
            }
            return state;

        case Types.EmptyPlan:
            return newPlanType;

        default:
            return state;
    }
};

export const mapVehiclesReducer = (state: Map<string, VehicleType>, action: PlanActions | CurrentPlanActions | VehicleActions): Map<string, VehicleType> => {
    switch (action.type) {
        case Types.SaveVehicles:
            return state;

        case Types.ListVehicle:

            return state;

        default:
            return state;
    }
};


//////////////////////////EXAMPLE
enum User {
    SignInObject = "User/SignInObject",
    SignInTuple = "User/SignInTuple",
    SignInSuccess = "User/SignInSuccess",
    SignInError = "User/SignInError"
}

type Messages = {
    [User.SignInObject]: { username: string; password: string };
    [User.SignInTuple]: [string, string];
    [User.SignInError]: string;
    [User.SignInSuccess]: undefined;
};

type Actions = ActionMap<Messages>[keyof ActionMap<Messages>];
const Msg = createMsg<Messages>();


// ------------- EVERYTHING BELOW IS EXAMPLES OF USE ---------------

// valid
Msg(User.SignInError, "Hi there");

// with tuples (valid)
Msg(User.SignInTuple, ["shakyshane", "awesomepw"]);

// valid
Msg(User.SignInObject, {
    username: "shakyshane",
    password: "123456"
});


// valid
Msg(User.SignInSuccess);


//  --------------- EXAMPLE OF REDUX REDUCER ----------------

interface UserState {
    username: null | string;
    password: null | string;
    message: null | string;
}

const initialState = {
    username: null,
    password: null,
    message: null
};

export function userReducer(state = initialState, action: Actions): UserState {
    switch (action.type) {
        case User.SignInObject: {
            return {
                ...state,
                ...action.payload
            };
        }

        case User.SignInError: {
            return {
                ...state,
                message: action.payload
            };
        }

        case User.SignInSuccess: {
            return state;
        }

        default:
            return state;
    }
}

/// ----------- THIS IS THE COPY/PASTE STUFF -------------------

export function createMsg<Obj extends { [index: string]: any }>() {
    return function <Key extends keyof Obj>(
        name: Key,
        ...args: Obj[Key] extends undefined ? [] : [Obj[Key]]
    ) {
        if (args.length > 0) {
            return {type: name, payload: args[0]};
        }
        return {type: name};
    };
}

export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
};

